import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import { Link as GatsbyLink } from 'gatsby'

const BlogLink = ({ children, href }) => {
  const style = {
    "color": "var(--theme-ui-colors-alpha,#667eea)"
  }

  const newTab = href.endsWith("#blank")
  if (newTab) {
    href = href.replace("#blank", "")
  }

  if (!newTab && href.startsWith('/')) {
    // Use Gatsby's Link component for internal site navigation
    // to benefit from the preloading features
    // See: https://www.gatsbyjs.org/docs/gatsby-link/
    return <GatsbyLink to={href} style={style}>{children}</GatsbyLink>
  }

  const onPage = href.startsWith('#')

  return <a
    href={href}
    style={style}
    // Open the link in a new page
    target={onPage && !newTab ? null : '_blank'}
    // Add noopener and noreferrer for security reasons
    rel={onPage ? null : 'noopener noreferrer'}
  >
    {children}
  </a>
}

export const PostBody = ({ body }) => {
  return <MDXProvider components={{
    a: BlogLink
  }}>
    <MDXRenderer>{body}</MDXRenderer>
  </MDXProvider>
}
